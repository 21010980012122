define("apollo/pods/components/planning/planned-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    intl: Ember.inject.service(),
    currentTransports: Ember.computed('transports.length', function () {
      return this.get('transports');
    }),
    validateDeliveryLoadCity: function (transport, delivery) {
      return transport.get('deliveries').every(d => {
        return d.get('loadStep.warehouse.address.city') === delivery.get('loadStep.warehouse.address.city');
      });
    },
    actions: {
      openModal() {
        this.set('action', 'showModal');
        const model = {
          transportType: this.get('transportType'),
          customFields: this.get('customFields')
        };
        this.sendAction('action', 'components.planning.modals.add-transport', model);
      },

      addDelivery(transport, obj) {
        if (!this.validateDeliveryLoadCity(transport, obj)) {
          this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.deliveryTransport.ADD_DELIVERY_TO_TRANSPORT.error'));
          console.error('Niezgodne miasta na załadunku !');
          return;
        }

        return this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.ADD_DELIVERY_TO_TRANSPORT, null, {
          body: JSON.stringify({
            transportId: transport.get('id'),
            deliveryId: obj.get('id')
          })
        });
      }

    }
  });

  _exports.default = _default;
});