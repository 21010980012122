define("apollo/pods/components/planning/not-planned-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    modalService: Ember.inject.service('modal-service'),
    totalWeight: Ember.computed('deliveries.@each.grossWeight', function () {
      const deliveries = this.get('deliveries');
      let totalWeight = 0;
      deliveries.forEach(delivery => {
        totalWeight += delivery.get('grossWeight');
      });
      return totalWeight;
    }),
    customFieldsPairs: Ember.computed('customFields', function () {
      const definitions = this.get('customFields').filterBy('id').filterBy('enabled').filterBy('obligatory').filterBy('visibleInTable').sortBy('idx').filter(it => {
        return !it.usedForDeliveries;
      });
      const pairs = [];

      for (let i = 0; i < definitions.get('length'); i++) {
        const definition = definitions.objectAt(i);
        const value = this.store.createRecord('customFieldValue', {
          definition
        });
        pairs[i] = {
          value,
          definition
        };
      }

      return pairs;
    }),
    canCreateDelivery: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_CREATE_DELIVERY');
    }),
    actions: {
      newDelivery() {
        const self = this;
        const delivery = this.store.createRecord('delivery');
        const transportType = this.get('transportType');
        const isLoadStepInWarehouseByDefault = transportType.get('isLoadStepInWarehouseByDefault');
        const isUnloadStepInWarehouseByDefault = transportType.get('isUnloadStepInWarehouseByDefault');
        const packages = this.store.peekAll('package-type-transport-type').filterBy('transportType.id', transportType.get('id'));
        const deliveryPackages = [];
        packages.forEach(p => {
          const dPackage = self.get('store').createRecord('delivery-packaging', {
            packageType: p.packageType.get('name')
          });
          deliveryPackages.push(dPackage);
        });
        delivery.set('packaging', deliveryPackages);
        const loadContactPersonDefaultName = this.sessionAccount.get('currentUser.name');
        const loadContactPersonDefaultPhone = this.sessionAccount.get('currentUser.mobilePhoneNumber');
        delivery.set('loadStep', self.get('store').createRecord('step', {
          ownedByPrincipal: isLoadStepInWarehouseByDefault,
          stepTypeName: 'load',
          orderInTransport: 0,
          contactPerson: loadContactPersonDefaultName,
          contactPersonMobilePhoneNumber: loadContactPersonDefaultPhone
        }));
        delivery.set('unloadStep', self.get('store').createRecord('step', {
          ownedByPrincipal: isUnloadStepInWarehouseByDefault,
          stepTypeName: 'unload',
          orderInTransport: 1
        }));
        const customFields = transportType.get('customFieldDefinitions');
        const deliveryCustomFields = delivery.get('deliveryCustomFieldValues');
        customFields.forEach(cf => {
          const deliveryCf = self.store.createRecord('delivery-custom-field-value', {
            definition: cf,
            delivery
          });
          deliveryCustomFields.pushObject(deliveryCf);
        });
        const model = {
          customFields: this.get('customFieldsPairs'),
          delivery,
          transportType
        };
        const modalPath = this.get('modalService').MODALS.DELIVERIES.CREATE;
        this.get('modalService').showModal(modalPath, model);
      },

      releaseDelivery(obj) {
        return this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.REMOVE_DELIVERY_FROM_TRANSPORT, null, {
          body: JSON.stringify({
            deliveryId: obj.get('id')
          })
        });
      }

    }
  });

  _exports.default = _default;
});