define("apollo/pods/planning/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jLBh1eXj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[11,\"style\",\"width: 1920px\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"delivery.planningTabTitle\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[27,\"planning/not-planned-panel\",null,[[\"deliveries\",\"customFields\",\"transportType\"],[[23,[\"model\",\"deliveries\"]],[23,[\"model\",\"customFields\"]],[23,[\"model\",\"transportType\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-6\"],[9],[0,\"\\n        \"],[1,[27,\"planning/planned-panel\",null,[[\"transports\",\"transportType\",\"customFields\"],[[23,[\"model\",\"transports\"]],[23,[\"model\",\"transportType\"]],[23,[\"model\",\"customFields\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/planning/index/template.hbs"
    }
  });

  _exports.default = _default;
});