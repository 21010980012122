define("apollo/pods/transport/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: Object.freeze({
      steps: {
        embedded: 'save'
      },
      advice: {
        embedded: 'save'
      },
      customFields: {
        embedded: 'save'
      },
      indexes: {
        embedded: 'save'
      },
      courierPackages: {
        embedded: 'save'
      },
      periodicTransport: {
        embedded: 'save'
      },
      courierInvoice: {
        embedded: 'save'
      },
      weights: {
        embedded: 'save'
      },
      amountOfCover: {
        embedded: 'save'
      },
      deliveries: {
        embedded: 'save'
      }
    }),
    serializeIntoHash: function (data, type, record, options) {
      const object = this.serialize(record, options);

      for (const key in object) {
        data[key] = object[key];
      }
    },
    serializeHasMany: function (record, json, relationship) {
      const DEFAULT_FORMAT_DATE = 'DD.MM.YYYY HH:mm';
      const key = relationship.key;
      const hasManyRecords = record.hasMany(key);

      if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === 'save') {
        json[key] = [];
        hasManyRecords.forEach(function (item) {
          let data = item.attributes();
          data.id = item.id;

          if (relationship.options.deepEmbedded) {
            relationship.options.deepEmbedded.forEach(function (deepKey) {
              if (item.belongsTo(deepKey)) {
                const deepRecord = item.belongsTo(deepKey);

                if (deepKey === 'company' && !deepRecord.attr('name')) {
                  return true;
                }

                const deepRecordIsUnsaved = !deepRecord.id;

                if (deepRecordIsUnsaved) {
                  data[deepKey] = deepRecord.attributes();
                  data[deepKey].id = deepRecord.id;
                } else {
                  data[deepKey] = {
                    id: deepRecord.id
                  };
                }

                if (deepKey === 'timeWindow') {
                  data[deepKey].start = moment(deepRecord.attr('start')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].stop = moment(deepRecord.attr('stop')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].initialStart = moment(deepRecord.attr('initialStart')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].firstSavedStart = moment(deepRecord.attr('firstSavedStart')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].ramp = deepRecord.belongsTo('ramp') ? {
                    id: deepRecord.belongsTo('ramp').id
                  } : null;
                } else if (deepKey === 'company' && deepRecordIsUnsaved) {
                  data[deepKey].country = deepRecord.belongsTo('country') ? {
                    id: deepRecord.belongsTo('country').id
                  } : null;
                } else if (deepKey === 'address' && deepRecordIsUnsaved) {
                  data[deepKey].country = deepRecord.belongsTo('country') ? deepRecord.belongsTo('country').id : null;
                  const companyId = deepRecord.belongsTo('company') ? deepRecord.belongsTo('company').id : data.company.id;
                  data[deepKey].company = companyId ? {
                    id: companyId
                  } : null;
                }
              }
            });
          }

          if (item.modelName === 'step') {
            if (data.arrivalDate) {
              data.arrivalDate = moment(data.arrivalDate).format(DEFAULT_FORMAT_DATE);
            }

            if (data.minAdviceDate) {
              data.minAdviceDate = moment(data.minAdviceDate).format(DEFAULT_FORMAT_DATE);
            }

            if (data.maxAdviceDate) {
              data.maxAdviceDate = moment(data.maxAdviceDate).format(DEFAULT_FORMAT_DATE);
            }

            if (item.hasMany('stepWarehouseLocations')) {
              data.stepWarehouseLocations = [];
              item.hasMany('stepWarehouseLocations').forEach(wl => {
                const d = wl.serialize();
                d.id = wl.id;
                d.step = item.id;
                data.stepWarehouseLocations.push(d);
              });
            }

            if (item.belongsTo('warehouseZone') && item.belongsTo('warehouseZone').id) {
              data.warehouseZone = {
                id: item.belongsTo('warehouseZone').id
              };
            }

            if (item.hasMany('stepPackageTypes')) {
              data.stepPackageTypes = [];
              item.hasMany('stepPackageTypes').forEach(spt => {
                const d = spt.attributes();
                d.id = spt.id;
                d.packageType = {
                  id: spt.belongsTo('packageType').id
                };
                d.step = {
                  id: spt.belongsTo('step').id
                };
                data.stepPackageTypes.push(d);
              });
            }
          }

          if (item.modelName === 'index' && item.hasMany('quantities')) {
            const relatedStep = item.belongsTo('step');

            if (relatedStep && relatedStep.attributes()) {
              data.stepOrderInTransport = relatedStep.attributes().orderInTransport;
            }

            data.quantities = [];
            item.hasMany('quantities').forEach(quantity => {
              const d = quantity.attributes();
              d.id = quantity.id;
              d.indexQuantityType = {
                id: quantity.belongsTo('indexQuantityType').id
              };
              data.quantities.push(d);
            });
          }

          if (item.modelName === 'weight') {
            if (data.enabled) {
              data = item.serialize();
            } else {
              return;
            }
          }

          json[key].push(data);
        });
      } else {
        this._super(record, json, relationship);
      }
    },
    serializeBelongsTo: function (record, json, relationship) {
      const DEFAULT_FORMAT_DATE = 'DD.MM.YYYY HH:mm';
      const key = relationship.key;
      const embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === 'save') {
        const data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = {
                id: embeddedRecord.belongsTo(deepKey).id
              };
            }
          });
        }

        if (relationship.type === 'periodic-transport') {
          if (data.startDate) {
            data.startDate = moment(data.startDate).format(DEFAULT_FORMAT_DATE);
          }

          if (data.endDate) {
            data.endDate = moment(data.endDate).format(DEFAULT_FORMAT_DATE);
          }
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});