define("apollo/pods/components/trucks/main-data-section/component", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    sessionAccount: service('session-account'),
    store: service(),
    isMoTEnabled: Ember.computed('truck.status', function () {
      return this.get('truck.status') !== 'DISABLED';
    }),
    isMoTNotAccepted: Ember.computed('truck.status', function () {
      return this.get('truck.status') !== 'ACCEPTED';
    }),
    canApprove: Ember.computed('truck.verificationCompleted', function () {
      return !this.get('truck.verificationCompleted') && this.get('sessionAccount').hasRole('ROLE_APPROVE_MOT');
    }),
    showInvalidRequiredDocumentTypesInfo: Ember.computed('truck.id', 'invalidRequiredDocumentTypes', function () {
      return this.get('invalidRequiredDocumentTypes.length') && this.get('truck.id');
    }),
    invalidRequiredDocumentTypes: Ember.computed('truck.documents.@each.state', function () {
      const self = this;
      const requiredDocumentTypes = self.get('store').peekAll('document-type').filter(dt => {
        return dt.get('isRequiredForModeOfTransportation');
      });
      const invalidDocumentTypes = [];
      requiredDocumentTypes.forEach(dt => {
        const acceptedDocumentsOfType = self.get('truck.acceptedDocuments').filter(doc => {
          return doc.get('documentType.name') === dt.get('name');
        });

        if (!acceptedDocumentsOfType || acceptedDocumentsOfType.get('length') === 0) {
          invalidDocumentTypes.push(dt);
        }
      });
      return invalidDocumentTypes;
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },

      changeTruckStatus(status, enabled) {
        const self = this;
        self.get('truck').setProperties({
          enabled: enabled,
          status: status
        });
        self.get('truck').save().then(() => {
          self.transitionTo('mode-of-transportation.index');
        });
      }

    }
  });

  _exports.default = _default;
});