define("apollo/pods/components/planning/not-planned-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PfsJKnPY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-lg-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[11,\"style\",\"font-weight: bold; font-size: 18px;padding-right: 8px;height: 52px;vertical-align: middle\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"style\",\"padding-top: 5px\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"delivery.toPlan\"],null],false],[0,\"\\n\"],[4,\"if\",[[23,[\"canCreateDelivery\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"button\"],[11,\"class\",\"btn btn-info pull-right\"],[11,\"style\",\"margin-top: -6px\"],[9],[1,[27,\"t\",[\"common.add\"],null],false],[3,\"action\",[[22,0,[]],\"newDelivery\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n\"],[4,\"draggable-object-target\",null,[[\"action\"],[[27,\"action\",[[22,0,[]],\"releaseDelivery\"],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"planning/delivery-table\",null,[[\"deliveries\",\"transportType\",\"filters\"],[[23,[\"deliveries\"]],[23,[\"transportType\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"panel-footer\"],[11,\"style\",\"padding-right: 8px;padding-left: 8px;height: 42px\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"pull-right\"],[11,\"data-field-name\",\"unplanned-total-weight\"],[9],[1,[21,\"totalWeight\"],false],[0,\" kg\"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/planning/not-planned-panel/template.hbs"
    }
  });

  _exports.default = _default;
});