define("apollo/pods/components/transport-types-nav-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    showTransportTypeTransactionSettingTab: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_SETTINGS');
    }),
    showTransportTypeSettingsTab: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_TRANSPORT_TYPE');
    }),
    showTransportTypeAuctionSettingTab: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_AUCTION_SETTINGS');
    }),
    showCustomFieldSettingsTab: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS');
    }),
    showPackageTypeTransportTypeSettingTab: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE') && this.get('sessionAccount').getSettingValue('PACKAGE_TYPES_FEATURE_ENABLED') === "true";
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    })
  });

  _exports.default = _default;
});