define("apollo/mixins/section-mixin", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    errors: [],
    actions: {
      save(section, route = null) {
        this.send('update', section, route);
      },

      update(section, route = null) {
        console.log(`Updating section ${section.get('id')}..`);
        const self = this;
        section = section.get('content') ? section.get('content') : section;

        if (!section.validate()) {
          self.set('successMessage', '');
          return;
        }

        section.save().then(response => {
          self.send('refreshSection', section);
          self.set('errors', '');
          self.set('successMessage', this.get('intl').t('section.successMessage'));

          if (route !== null) {
            // todo ups truck.id zostało dodane z rozpędu, oczywiście nie może tak być w tak ogólnej metodzie
            self.transitionTo(route, response.get('truck.id'));
          }
        }).catch(response => {
          self.set('successMessage', '');
          self.set('errors', response.errors);
          self.send('refreshSection', section);
        });
      },

      confirm: function (section) {
        if (section.hasChangesApartFromActions ? section.hasChangesApartFromActions() : section.content.hasChangesApartFromActions()) {
          const self = this;
          section = section.get('content') ? section.get('content') : section;
          section.save().then(() => self.send('executeAction', 'sections', section, 'confirm'));
        } else {
          this.send('executeAction', 'sections', section, 'confirm');
        }
      },
      reject: function (section, comment) {
        if (section.hasChangesApartFromActions ? section.hasChangesApartFromActions() : section.content.hasChangesApartFromActions()) {
          const self = this;
          section = section.get('content') ? section.get('content') : section;
          section.save().then(() => self.send('executeAction', 'sections', section, 'reject', comment));
        } else {
          this.send('executeAction', 'sections', section, 'reject', comment);
        }
      },

      disable(section) {
        this.send('executeAction', 'documents', section, 'disable');
      },

      executeAction(realm, section, actionName, comment) {
        const self = this;
        const token = this.get('sessionAccount').get('token');
        self.set('operationInProgress', true); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: `${_environment.default.serverURL}/${realm}/${section.get('id')}/${actionName}`,
          type: 'POST',
          data: {
            comment
          },
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log(`Section ${section.get('id')} has been ${actionName}ed.`);
          self.set('operationInProgress', false);
          self.send('refreshSection', section);
          self.set('errors', []);
        }).catch(response => {
          self.set('errors', JSON.parse(response.responseText).errors);
        });
      },

      refreshSection(section) {
        const modelName = section.get('constructor.modelName') ? section.get('constructor.modelName') : section.get('_internalModel.modelName');
        this.get('store').findRecord(modelName, section.get('id'), {
          reload: true
        }).then(s => {
          if (s.get('partnership.id')) {
            s.get('partnership').then(p => p.reload());
          } else {
            s.get('truck').then(t => t.reload());
          }

          if (s.get('activeAction')) {
            s.get('activeAction').reload();
          }
        });
      }

    }
  });

  _exports.default = _default;
});