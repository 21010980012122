define("apollo/pods/components/planning/modals/add-transport/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = Ember.Controller.extend({
    store: service(),
    deliveryService: service(),
    intl: service(),
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    }),
    customFields: Ember.computed('model.customFields', function () {
      const definitions = this.get('model.customFields').filterBy('id').filterBy('enabled').filterBy('obligatory').filterBy('visibleInTable').sortBy('idx').filter(it => {
        return !it.usedForDeliveries;
      });
      const pairs = [];

      for (let i = 0; i < definitions.get('length'); i++) {
        const definition = definitions.objectAt(i);
        const value = this.store.createRecord('customFieldValue', {
          definition
        });
        pairs[i] = {
          value,
          definition
        };
      }

      return pairs;
    }),
    actions: {
      save() {
        if (this.get('customFields').some(cf => !cf.value.get('value') && !cf.value.get('option.id'))) {
          this.set('errors', Ember.A([this.get('intl').t('errors.blankSelect')]));
          return;
        }

        this.deliveryService.createNewBasicTransport(this.get('customFields'));
        this.set('errors', Ember.A([]));
        this.send('hideModal');
      },

      cancel() {}

    }
  });

  _exports.default = _default;
});