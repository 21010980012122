define("apollo/pods/components/transport/step-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    DEFAULT_ARRIVAL_TIME_ARRAY: Ember.A(['18', '00']),

    init() {
      this._super(...arguments);

      this.autoCompleteContactPersonDetails();
    },

    classNames: Ember.A(['step-responsive-width']),
    autoCompleteContactPersonDetails: function () {
      if (this.get('step.transport.id') || !this.get('step.transport.transportType.isContactDetailsFieldsVisible')) {
        // uzupełniamy tylko w tworzonym zleceniu z widocznymi polami osoby kontaktowej
        return;
      }

      const currentUser = this.get('sessionAccount.currentUser');
      const name = this.get('step.contactPerson');
      const email = this.get('step.contactPersonEmail');
      const mobilePhoneNumber = this.get('step.contactPersonMobilePhoneNumber');
      const mobilePhoneNumberPrefix = this.get('step.contactPersonMobilePhonePrefix');

      if (email === '' || email === null || email === undefined) {
        this.set('step.contactPersonEmail', currentUser.get('email'));
      }

      if (mobilePhoneNumber === '' || mobilePhoneNumber === null || mobilePhoneNumber === undefined) {
        this.set('step.contactPersonMobilePhoneNumber', currentUser.get('mobilePhoneNumber'));
      }

      if (name === '' || name === null || name === undefined) {
        this.set('step.contactPerson', currentUser.get('name'));
      }

      const currentUserMobilePhonePrefix = currentUser.get('mobilePhoneNumberPrefix');

      if (mobilePhoneNumberPrefix === '' || mobilePhoneNumberPrefix === null || mobilePhoneNumberPrefix === undefined) {
        if (currentUserMobilePhonePrefix !== null && currentUserMobilePhonePrefix !== '') {
          this.set('step.contactPersonMobilePhonePrefix', currentUserMobilePhonePrefix);
        } else {
          this.set('step.contactPersonMobilePhonePrefix', '48');
        }
      }
    },
    addNewAddress: Ember.computed('step.newAddressAsDefault', function () {
      return this.get('step.newAddressAsDefault');
    }),
    defaultPrefix: Ember.computed('step.contactPersonMobilePhonePrefix', function () {
      return this.get('step.contactPersonMobilePhonePrefix');
    }),
    masterSteps: Ember.computed('step.transport.masterTransports.firstObject.steps', function () {
      return this.get('step.transport.masterTransports.firstObject.steps');
    }),
    stepOrMasterStep: Ember.computed('step.{[],warehouse.id,transport}', 'masterSteps.@each.warehouse', function () {
      const self = this;
      const masterSteps = this.get('masterSteps');
      const transport = this.get('step.transport');
      const isTransportInHasToWaitFor = transport.get('relatedHasToWaitForTransports');

      if (!masterSteps || isTransportInHasToWaitFor) {
        return this.get('step');
      }

      return masterSteps.filter(s => {
        return s.get('warehouse.id') && s.get('warehouse.id') === self.get('step.warehouse.id');
      }).get('firstObject') || this.get('step');
    }),
    stepOrMasterTransportStepHasTimeWindow: Ember.computed('stepOrMasterStep.timeWindow.id', function () {
      return !!this.get('stepOrMasterStep.timeWindow.id');
    }),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),
    companyType: 'SUPPLIER',
    address: Ember.computed('step.address', function () {
      return this.get('step.address');
    }),
    arrivalDate: Ember.computed('step.arrivalDate', function () {
      return moment(this.get('step.arrivalDate')).format('YYYY-MM-DD HH:mm');
    }),
    addressSummary: Ember.computed('address.{recipient,city,postal,street}', function () {
      if (this.get('address.recipient')) {
        return this.get('address.recipient');
      }

      if (this.get('address.street')) {
        return `${this.get('address.street')}, ${this.get('address.city')}, ${this.get('address.postal')}`;
      }

      return '';
    }).readOnly(),
    addressAutoCompleteDisabled: Ember.computed('transport.disabled', 'step.createdFromRoute', function () {
      return this.get('step.createdFromRoute') || this.get('transport.disabled');
    }),
    canChooseCompany: Ember.computed('transport', function () {
      return !this.get('step.createdFromRoute') || this.get('transport').actionCanBePerformed('ACCESS_SUPPLIER_COMPANY_FIELD');
    }),
    showNewAddressButton: Ember.computed('step.company.id', 'transport.canBeUpdated', function () {
      if (this.get('step.createdFromRoute')) {
        return false;
      }

      if (this.get('canChooseCompany')) {
        return this.get('step.company.id') && this.get('transport.canBeUpdated');
      }

      return this.get('transport.canBeUpdated');
    }),
    showWarehouseTimes: Ember.computed('step.{warehouse,ownedByPrincipal,timeWindow}', 'transport.transportType.requiresAdviceDateRestrictions', function () {
      return this.get('step.isInWarehouse') && !this.get('transport.transportType.requiresAdviceDateRestrictions');
    }),
    showWarehouseZone: Ember.computed('transport.transportType.isWarehouseZoneVisible', function () {
      return this.get('transport.transportType.isWarehouseZoneVisible');
    }),
    showWarehouseLocation: Ember.computed('transport.transportType.isWarehouseLocationVisible', function () {
      return this.get('transport.transportType.isWarehouseLocationVisible') && this.get('transport').actionCanBePerformed('ROLE_ACCESS_TO_LOCATION');
    }),
    warehouseLocationDisabled: Ember.computed('transport.transportType.authorityRestrictions.@each.authorityRange', function () {
      return !this.get('transport').actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION');
    }),
    showContactPerson: Ember.computed('transport.transportType.isContactDetailsFieldsVisible', function () {
      return this.get('transport.transportType.isContactDetailsFieldsVisible');
    }),
    companyToFindAddress: Ember.computed('step.company.id', 'sessionAccount.currentUser.company.id', function () {
      return this.get('step.company.id') ? this.get('step.company.id') : this.get('sessionAccount.currentUser.company.id');
    }),
    shouldDataBeHidden: Ember.computed('transport.{id,transportType.authorityRestrictions.@each.authorityRange,firstUnconfirmedCheckpoint}', function () {
      if (!this.get('transport.id')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('DISABLE_DATA_FIELDS');
    }),
    alternativeStepsLayout: Ember.computed('transport.id', function () {
      return this.get('transport.transportType.alternativeStepsLayout');
    }),
    StepCountryField: Ember.computed('step.address.country', function () {
      return this.get('step.address.country');
    }),
    arrivalDateInWarehouseStep: Ember.computed('step.arrivalDate', function () {
      const arrivalDate = this.get('step.arrivalDate'); // Jesli obiekt arrivalDate nie jest typu 'Date' to zwracamy pusty string

      if (arrivalDate instanceof Date) {
        return moment(arrivalDate).format('YYYY-MM-DD HH:mm');
      }

      return '';
    }),
    actions: {
      setArrivalDate(step, dateValues) {
        Ember.run.next(() => {
          step.set('arrivalDate', dateValues[0]);
        });
      },

      setPhonePrefix(phonePrefix) {
        this.set('step.contactPersonMobilePhonePrefix', phonePrefix);
      },

      setContractorCompanyData(step, item) {
        // troche haks - poniższe zapytania i walidacje długo trwają, więc od razu wrzucamy id nowej firmy,
        // żeby przy "szybkim klikaniu" podpowiedzieć już właściwe adresy
        this.set('companyToFindAddress', item.id);
        this.get('store').findRecord('company', item.id).then(company => {
          const transport = this.get('transport');
          const oldCompany = step.get('company');

          if (transport.checkIfEditionViolatesPeriodicTransportRules(oldCompany, company)) {
            alert(this.get('intl').t('periodicTransport.cannotChangeCompany'));
            step.set('company', oldCompany);
            return;
          }

          step.set('companyGroup', null);
          step.set('company', company);
          step.set('address', null);

          if (this.get('addNewAddress')) {
            this.send('setAddressForStep', step);
          }
        });
      },

      removeContractorCompany() {
        console.debug('Removing company from step..');
        this.set('companyToFindAddress', this.get('sessionAccount.currentUser.company.id'));
        this.get('step').set('company', null);
      },

      selectContractorCompany(id) {
        if (!id) {
          return;
        }

        const self = this;
        this.get('store').findRecord('company', id).then(company => {
          self.get('transport').set('contractorCompany', company);
        });
      },

      offScroll() {
        $(':input').on('mousewheel', function (event) {
          event.preventDefault();
        });
      },

      toggleNewAddress(step) {
        if (this.get('addNewAddress')) {
          this.send('deleteAddressFromStep', step);
          this.set('addNewAddress', false);
        } else {
          this.send('setAddressForStep', step);
          this.set('addNewAddress', true);
        }
      },

      deleteAddressFromStep(step) {
        step.get('address').then(address => {
          address.unloadRecord();
          step.set('address', null);
        });
      },

      setAddressForStep(step) {
        step.set('address', this.get('store').createRecord('address', {
          company: step.get('company')
        }));
      },

      async setAddressData(address) {
        const addressModel = await this.get('store').findRecord('address', address.id);

        if (address.id) {
          this.get('step').set('address', addressModel);
        } else {
          this.get('step.address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('step.address').set('country', addressModel.country);
        }

        const addressUnloadingTime = await addressModel.get('addressUnloadingTime');

        if (addressUnloadingTime && addressUnloadingTime.get('id')) {
          const newArrivalDate = moment().startOf('day').add(addressUnloadingTime.get('daysToUnload') || 0, 'day');
          const arrivalDayName = addressUnloadingTime.get('dayName')[newArrivalDate.day()];
          const arrivalDayHourAndMinute = addressUnloadingTime.get(`${arrivalDayName}`) ? addressUnloadingTime.get(`${arrivalDayName}`).split(':') : this.DEFAULT_ARRIVAL_TIME_ARRAY;
          const arrivalDayHour = arrivalDayHourAndMinute[0];
          const arrivalDayMinute = arrivalDayHourAndMinute[1];
          newArrivalDate.add(arrivalDayHour, 'hours').add(arrivalDayMinute, 'minutes');
          this.set('step.arrivalDate', newArrivalDate.toDate());
        } else {
          if (!this.get('step.arrivalDate')) {
            return;
          }

          const defaultArrivalDate = moment().startOf('day').add(this.DEFAULT_ARRIVAL_TIME_ARRAY[0], 'hours').add(this.DEFAULT_ARRIVAL_TIME_ARRAY[1], 'minutes');
          const daysToAdd = await this.get('step.transport.transportType.numberOfDaysAddedToFirstStep');

          if (daysToAdd) {
            defaultArrivalDate.add(daysToAdd);
          }

          this.set('step.arrivalDate', defaultArrivalDate.toDate());
        }
      },

      removeAddress() {
        console.debug('Removing address from step..');
        this.get('step').set('address', null);
      },

      setWarehouse(warehouse) {
        this.get('step').set('warehouse', warehouse);
        this.get('step.errors').remove('warehouse');
      }

    }
  });

  _exports.default = _default;
});