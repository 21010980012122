define("apollo/pods/courier-package/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    transport: belongsTo('transport', {
      async: false
    }),
    packageType: belongsTo('packageType', {
      async: true
    }),
    packageLength: attr('number'),
    packageWidth: attr('number'),
    packageHeight: attr('number'),
    packageWeight: attr('number'),
    packageValue: attr('number'),
    packageDescription: attr('string'),
    numberOfPackages: attr('number'),
    lastUpdated: attr('isodate'),
    idx: attr('number'),
    enabled: attr('boolean'),
    ordinalNumber: Ember.computed('idx', function () {
      return this.get('idx') + 1;
    }).readOnly(),
    sortValue: Ember.computed('id', function () {
      return this.get('id') || Number.MAX_SAFE_INTEGER.toString();
    }),
    internalId: Ember.computed('id', function () {
      return this.get('id');
    }),
    hasChanges: Ember.computed('hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') > 0;
    }),
    totalValue: Ember.computed('numberOfPackages', 'packageValue', function () {
      return (Math.round(this.get('numberOfPackages') * this.get('packageValue') * 100) / 100).toFixed(2) || 0;
    }),
    validations: {
      packageType: {
        custom: [{
          validation: function (key, value) {
            return value.content;
          },
          message: function (key, value, model) {
            return model.get('intl').t('errors.blankSelect');
          }
        }]
      },
      packageLength: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('courier.validation.range');
          }
        }]
      },
      packageWidth: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('courier.validation.range');
          }
        }]
      },
      packageHeight: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('courier.validation.range');
          }
        }]
      },
      packageWeight: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 0.001 || value > 999999999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('courier.validation.float.range');
          }
        }]
      },
      packageValue: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 0.001 || value > 999999999999) {
              return false;
            }

            return /^[0-9]*[.|,]?[0-9]*$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('courier.validation.float.range');
          }
        }]
      },
      packageDescription: {
        custom: [{
          validation: function (key, value) {
            return value;
          },
          message: function (key, value, model) {
            return model.get('intl').t('errors.blank');
          }
        }]
      },
      numberOfPackages: {
        custom: [{
          validation: function (key, value, model) {
            if (!value) {
              return false;
            }

            const formattedValue = Number(value.toString().replace(',', '.'));

            if (formattedValue) {
              value = formattedValue;
              model.set(key, value);
            }

            if (value < 1 || value > 9999999) {
              return false;
            }

            return /^\d+$/.test(value);
          },
          message: function (key, value, model) {
            return model.get('intl').t('courier.validation.integer.range');
          }
        }]
      }
    }
  });

  _exports.default = _default;
});