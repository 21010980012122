define("apollo/pods/planning/modals/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    modalTitle: Ember.computed('model.delivery.id', function () {
      return this.get('model.delivery.id') ? this.get('intl').t('delivery.edit') : this.get('intl').t('delivery.create');
    }),
    actions: {
      cancel() {
        this.get('model.delivery').rollbackAttributes();
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});