define("apollo/pods/truck-types/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modalService: Ember.inject.service('modal-service'),
    errors: Ember.A([]),
    successMessage: null,
    company: Ember.computed('model.company.id', function () {
      return this.get('model.company');
    }),
    transportTypes: Ember.computed('model.transportTypes.@each.id', function () {
      return this.get('model.transportTypes');
    }),
    truckTypes: Ember.computed('model.transportTypes.@each.id', function () {
      return this.get('model.truckTypes');
    }),
    actions: {
      add: function () {
        const truckTypeMeta = this.get('store').createRecord('truckTypeMeta');
        this.get('modalService').showModal(this.get('modalService').MODALS.TRUCK_TYPE.FORM, Ember.ObjectProxy.create({
          truckType: this.get('store').createRecord('truckType', {
            truckTypeMeta
          }),
          transportTypes: this.get('model.transportTypes')
        }));
      },
      edit: function (truckType) {
        this.get('modalService').showModal(this.get('modalService').MODALS.TRUCK_TYPE.FORM, Ember.ObjectProxy.create({
          truckType,
          transportTypes: this.get('transportTypes')
        }));
      }
    }
  });

  _exports.default = _default;
});