define("apollo/pods/delivery-packaging/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    amount: attr('number'),
    packageType: attr('string'),
    validations: {
      amount: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});