define("apollo/pods/checkpoint-confirmation-modal/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin", "apollo/models/checkpoint-confirmation-modal", "apollo/utils/parsers/numberParsers"], function (_exports, _transportsMixin, _adviceUtilsMixin, _checkpointConfirmationModal, _numberParsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    checkpointApiService: Ember.inject.service('api.checkpoint-api-service'),
    weightService: Ember.inject.service('weight-service'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    errors: Ember.A(),
    selectedWarehouseLocations: Ember.A([]),
    checkpoint: Ember.computed.reads('model.checkpointModel'),
    weight: Ember.computed.reads('model.weight'),
    isOverweight: Ember.computed.reads('model.isOverweight'),
    isLoading: false,
    step: Ember.computed.reads('checkpoint.step'),
    transport: Ember.computed('checkpoint.transport.id', function () {
      const transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.id', function () {
      return `${this.get('intl').t('checkpoints.confirm')}: ${this.get('checkpoint.name')}`;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    warehouseLocationDisabled: Ember.computed('transport.transportType.authorityRestrictions.@each.authorityRange', function () {
      return !this.get('transport').actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION');
    }),
    visibleCustomFieldsPairsWithCurrentTaskBlockade: Ember.computed('firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade', function () {
      const self = this;
      const customFieldsPairsWithCurrentTaskBlockade = self.get('firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade');
      return customFieldsPairsWithCurrentTaskBlockade.filter(pair => {
        return !pair.value.value && !pair.value.option || self.shouldBeVisible(pair);
      });
    }),
    classes: Ember.computed('visibleCustomFieldsPairsWithCurrentTaskBlockade.length', function () {
      const customFieldsLength = this.get('visibleCustomFieldsPairsWithCurrentTaskBlockade.length');
      return customFieldsLength > Number.parseInt(this.get('customFieldSplitValue')) ? 'super-wide-modal' : '';
    }),
    whlRequired: false,
    customFieldSplitValue: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CUSTOM_FIELD_SPLIT_VALUE');
    }),
    shouldBeVisible: function (pair) {
      return pair.value.get('isVisibleBasedOnDefinition') && pair.value.get('isVisibleBasedOnAuthorityRestrictions');
    },
    toggleLoading: function () {
      this.set('isLoading', !this.get('isLoading'));
    },
    actions: {
      async confirmCheckpoint(checkpoint) {
        try {
          this.toggleLoading();
          checkpoint.set('isDuringAction', true);
          const customFieldValues = this.get('visibleCustomFieldsPairsWithCurrentTaskBlockade').map(cfPair => cfPair.get('value'));
          customFieldValues.filterBy('definition.isNumberType').forEach(pair => {
            if (pair.get('value')) {
              pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
            }
          });
          const weight = this.get('weight');

          if (!this.validateWeightAndCustomFields(weight, customFieldValues)) {
            this.toggleLoading();
            return;
          }

          if (!this.validateWarehouseLocations(checkpoint)) {
            this.toggleLoading();
            return;
          }

          const modalDataModel = _checkpointConfirmationModal.default.create({
            checkpoint: checkpoint.get('id'),
            warehouseLocations: this.get('selectedWarehouseLocations').map(wl => wl.get('id')),
            customFieldValues,
            weight
          });

          const savedDataResponse = await this.get('checkpointApiService').saveModalData(modalDataModel);

          if (!savedDataResponse.ok) {
            this.set('errors', [this.get('intl').t('common.defaultError')]);
            return;
          }

          await this.get('checkpointService').confirmAndRefresh(checkpoint);

          if (this.get('isOverweight')) {
            await this.get('weightService').saveOverweightNote(checkpoint);
          }

          this.send('hideModal');
          this.toggleLoading();
        } catch (e) {
          this.set('errors', [e.message]);
          this.toggleLoading();
          console.error(e);
        } finally {
          checkpoint.set('isDuringAction', false);
        }
      },

      rejectCheckpoint(checkpoint) {
        const self = this;
        self.get('transport').reload().then(transport => {
          this.executeAction(self, checkpoint, transport, function () {
            self.send('reject', checkpoint);
          });
        });
      },

      cancel(checkpoint) {
        if (this.get('weight')) {
          this.get('weight').rollbackAttributes();
        }

        if (checkpoint.get('hasWarehouseLocationBlockade')) {
          const locations = checkpoint.get('step.stepWarehouseLocations.length') ? checkpoint.get('step.stepWarehouseLocations.length') : [];

          if (locations > 0) {
            this.set('checkpoint.step.stepWarehouseLocations', Ember.A([]));
          }

          this.set('selectedWarehouseLocations', Ember.A([]));
        }

        this.reset(checkpoint);
      }

    },

    validateWeightAndCustomFields(weight, customFieldValues) {
      if (weight && !weight.validate()) {
        return false;
      }

      if (customFieldValues.length && customFieldValues.some(cfv => !cfv.validate())) {
        return false;
      }

      return true;
    },

    validateWarehouseLocations(checkpoint) {
      if (checkpoint.get('hasWarehouseLocationBlockade')) {
        this.set('whlRequired', true);
        return !!this.get('selectedWarehouseLocations').length;
      }

      this.set('whlRequired', false);
      return true;
    },

    reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }

  });

  _exports.default = _default;
});