define("apollo/services/delivery-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    store: Ember.inject.service(),

    createNewBasicTransport(customFields) {
      const values = customFields.map(pair => pair.value);
      return this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.CREATE_BASIC_TRANSPORT, null, {
        body: JSON.stringify({
          values
        })
      });
    }

  });

  _exports.default = _default;
});