define("apollo/pods/planning/index/route", ["exports", "apollo/mixins/menu-mixin"], function (_exports, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    websocket: Ember.inject.service('websocket'),
    planningPollingService: Ember.inject.service('polling.planning-service'),
    access: Ember.A(['ROLE_PLANNING_TAB']),
    transports: Ember.A([]),
    model: function () {
      return Ember.RSVP.hash({
        transportType: this.store.peekAll('transportType').filterBy('usedForDeliveries').get('firstObject'),
        deliveries: this.store.query('delivery', {}),
        transports: this.store.query('transport', {
          planning: true
        }),
        customFields: this.store.query('customFieldDefinition', {
          planning: true
        })
      });
    },
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    afterModel: function (model) {
      const allowWs = this.get('websocket.allowWebsockets');

      if (allowWs) {
        this.get('planningPollingService').useSockets(model);
      } else {
        this.get('planningPollingService').usePolling({}, this);
      }
    },

    activate() {
      this.activateCurrentTab('PLANNING_TAB');
    },

    refreshModel() {
      this.refresh();
    },

    actions: {
      willTransition() {
        const webSocket = this.get('websocket').getSocket();

        if (webSocket && webSocket.get('connected')) {
          // eslint-disable-next-line no-useless-escape
          webSocket.unsubscribeAny('topic\/planning\/update');
        }
      }

    }
  });

  _exports.default = _default;
});