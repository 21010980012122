define("apollo/pods/truck-type/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    languageService: Ember.inject.service('language-service'),
    truckTypeName: attr('string'),
    truckTypeNameEn: attr('string'),
    truckTypeMeta: belongsTo('truckTypeMeta', {
      async: false
    }),
    transportTypes: hasMany('transportType', {
      async: true,
      inverse: null
    }),
    validations: {
      truckTypeName: {
        presence: {
          message: 'blank'
        },
        length: {
          maximum: {
            value: 255,
            message: 'length255'
          }
        }
      },
      truckTypeNameEn: {
        presence: {
          message: 'blank'
        },
        length: {
          maximum: {
            value: 255,
            message: 'length255'
          }
        }
      }
    },
    nameInAppropriateLanguage: Ember.computed('truckTypeName', 'truckTypeNameEn', function () {
      const propertyName = this.get('languageService').findProperLanguageVersion('truckTypeName', 'truckTypeNameEn');
      return this.get(propertyName);
    })
  });

  _exports.default = _default;
});