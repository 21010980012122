define("apollo/pods/delivery/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator", "apollo/models/custom-field-pair"], function (_exports, _emberData, _model, _modelValidator, _customFieldPair) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, {
    deliveryNumber: attr('string'),
    netWeight: attr('number'),
    grossWeight: attr('number'),
    incoterms: attr('string'),
    parentDeliveryNumber: attr('string'),
    description: attr('string'),
    documentType: attr('string'),
    archived: attr('boolean'),
    modeOfTransport: attr('string'),
    freightCost: attr('number'),
    loadStep: belongsTo('step', {
      async: true
    }),
    unloadStep: belongsTo('step', {
      async: true,
      deepEmbedded: ['address']
    }),
    freightCostCurrency: belongsTo('currency', {
      async: true
    }),
    invoiceNumber: attr('string'),
    deliveryCustomFieldValues: hasMany('deliveryCustomFieldValue', {
      async: false
    }),
    packaging: hasMany('deliveryPackaging', {
      async: true
    }),
    realLoadDate: attr('date'),
    realUnloadDate: attr('date'),
    unloadContactPersonDetails: attr('string'),
    isFromSap: attr('boolean'),
    validations: {
      deliveryNumber: {
        presence: {
          message: 'blank'
        }
      },
      incoterms: {
        presence: {
          message: 'blankSelect'
        }
      },
      packaging: {
        relations: ['hasMany']
      },
      grossWeight: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function (key, value) {
            return Number(value) > 0;
          },
          message: 'positiveNumber'
        }]
      },
      loadStep: {
        custom: [{
          validation: function (key, value, model) {
            return !!model.get('loadStep').get('warehouse.id');
          },
          message: 'blankSelect'
        }]
      },
      unloadStep: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('unloadStep').get('address.id') && model.get('unloadStep').get('address').get('content')) {
              return model.get('unloadStep').get('address').get('content').validate();
            }

            return !!model.get('unloadStep').get('address.id');
          },
          message: 'blankSelect'
        }]
      },
      freightCost: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('freightCostCurrency')) {
              return true;
            }

            return /^\d+$/.test(value);
          },
          message: 'blank'
        }]
      },
      freightCostCurrency: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('freightCost')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      }
    },
    getPriceLabel: Ember.computed('documentType', 'deliveryNumber', 'id', function () {
      return this.get('documentType') ? this.get('documentType') + this.get('deliveryNumber') : this.get('deliveryNumber') || this.get('id');
    }),
    customFieldPairs: Ember.computed('deliveryCustomFieldValues', function () {
      const dcfv = this.get('deliveryCustomFieldValues');
      return dcfv.map(cf => {
        const definition = cf.get('definition'); // cfy wyświetlamy z pomocą komponentu `custom-field-value-formatted`, który jako value
        // oczekuje całego customfielda, zeby zmapować wartość za pomocą value lub option

        const value = cf;
        return _customFieldPair.default.create({
          definition,
          value
        });
      });
    })
  });

  _exports.default = _default;
});